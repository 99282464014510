@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.header {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 5;
  width: 100%;
  transform: translate(-50%, 0);
}
@media only screen and (max-width: 700px) {
  .header {
    background-color: #fff;
  }
}
.header .header-wrapper {
  display: flex;
  width: 100%;
  height: 4.1666666667vw;
  padding: 0 0.2083333333vw 0 1.40625vw;
  margin: 0 auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
@media only screen and (max-width: 700px) {
  .header .header-wrapper {
    width: 100%;
    height: 11.875vw;
    padding: 0 1.71875vw 0 3.75vw;
    align-items: center;
    background-color: #fff;
  }
}

.left-side {
  display: flex;
  align-items: center;
}

.right-side {
  display: flex;
  align-items: center;
}
.right-side .ynet-logo {
  position: relative;
  display: block;
  max-width: 6.28125vw;
  margin-right: 0.8072916667vw;
  margin-left: 0.8072916667vw;
}
@media only screen and (max-width: 700px) {
  .right-side .ynet-logo {
    max-width: 18.84375vw;
    margin-right: 2.421875vw;
    margin-left: 2.421875vw;
  }
}
.right-side .ynet-logo::after {
  content: "";
  position: absolute;
  top: 2px;
  right: -0.78125vw;
  width: 1px;
  height: 2.8645833333vw;
  background-color: #707070;
}
@media only screen and (max-width: 700px) {
  .right-side .ynet-logo::after {
    right: -2.34375vw;
    height: 8.59375vw;
  }
}
.right-side .ynet-logo::before {
  content: "";
  position: absolute;
  top: 2px;
  left: -0.78125vw;
  width: 1px;
  height: 2.8645833333vw;
  background-color: #707070;
}
@media only screen and (max-width: 700px) {
  .right-side .ynet-logo::before {
    left: -2.34375vw;
    height: 8.59375vw;
  }
}
.right-side .ynet-logo img {
  display: block;
  width: 100%;
}
.right-side .partner-logo {
  display: block;
  max-width: 6.9791666667vw;
  margin-left: 0.8072916667vw;
}
@media only screen and (max-width: 700px) {
  .right-side .partner-logo {
    max-width: 20.9375vw;
    margin-left: 2.421875vw;
  }
}
.right-side .partner-logo img {
  display: block;
  width: 100%;
}
.right-side .partner-logo2 {
  display: block;
  max-width: 5.8333333333vw;
  margin-right: 0.9635416667vw;
}
@media only screen and (max-width: 700px) {
  .right-side .partner-logo2 {
    max-width: 17.5vw;
    margin-right: 2.890625vw;
  }
}
.right-side .partner-logo2 img {
  display: block;
  width: 100%;
}

.logo {
  position: absolute;
  bottom: 0.5208333333vw;
  left: 50%;
  transform: translate(-50%, 0);
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .logo {
    bottom: vw_t(5);
  }
}
@media only screen and (max-width: 700px) {
  .logo {
    top: 50%;
    bottom: initial;
    transform: translate(-50%, -50%);
  }
}
.logo .centerImgW {
  height: 2.8645833333vw;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .logo .centerImgW {
    height: vw_t(35);
  }
}
@media only screen and (max-width: 700px) {
  .logo .centerImgW {
    height: 7.8125vw;
  }
}
.logo .centerImgW img {
  display: block;
  height: 100%;
}