@charset "UTF-8";
/* stylelint-disable */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.intro {
  position: relative;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: white;
  color: white;
  text-decoration: none;
  direction: rtl;
}

.form-screen {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100vh;
  min-height: 850px;
  padding-top: 454px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 0s;
}
@media only screen and (max-width: 700px) {
  .form-screen {
    height: auto;
    min-height: 261.09375vw;
    padding-top: 122.1875vw;
    background-size: contain;
    background-position: top;
  }
}
.form-screen.active {
  z-index: 4;
  display: block;
  opacity: 1;
}

.form-wrapper {
  position: relative;
  display: flex;
  width: 26.5625vw;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media only screen and (max-width: 700px) {
  .form-wrapper {
    width: 100%;
    padding: 0 4.21875vw 26.25vw;
    background-color: #e1eccf;
  }
}
.form-wrapper .field {
  width: 13.0208333333vw;
  margin-bottom: 0.7291666667vw;
}
@media only screen and (max-width: 700px) {
  .form-wrapper .field {
    width: 100%;
    margin-bottom: 2.34375vw;
  }
}
.form-wrapper .field.field-long {
  width: 100%;
}
.form-wrapper .field input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 1.71875vw;
  padding: 0 0.5208333333vw;
  line-height: 1.71875vw;
  font-size: 0.8333333333vw;
  background-color: #fff;
  border: solid 1px #75af5d;
  border-radius: 0.2604166667vw;
  outline: none;
  font-family: "open_sans_hebrew", sans-serif;
  color: #0a0201;
  direction: rtl;
}
@media only screen and (max-width: 700px) {
  .form-wrapper .field input {
    height: 10.3125vw;
    padding: 0 1.5625vw;
    line-height: 10.3125vw;
    font-size: 4.0625vw;
    border-radius: 0.78125vw;
  }
}
.form-wrapper .field input::placeholder {
  color: #0a0201;
}
.form-wrapper .field input.error {
  color: red;
  border: solid 1px red;
}
.form-wrapper .field input.error::placeholder {
  color: red;
}
.form-wrapper .field textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 6.8229166667vw;
  padding: 0.5208333333vw;
  font-size: 0.8333333333vw;
  line-height: 0.9375vw;
  background-color: #fff;
  border: solid 1px #75af5d;
  border-radius: 0.2604166667vw;
  resize: none;
  outline: none;
  font-family: "open_sans_hebrew", sans-serif;
  color: #0a0201;
  direction: rtl;
}
@media only screen and (max-width: 700px) {
  .form-wrapper .field textarea {
    height: 29.53125vw;
    padding: 2.65625vw 1.5625vw;
    font-size: 4.0625vw;
    line-height: 4.375vw;
    border-radius: 0.78125vw;
  }
}
.form-wrapper .field textarea::placeholder {
  color: #0a0201;
}
.form-wrapper .field textarea.error {
  color: red;
  border: solid 1px red;
}
.form-wrapper .field textarea.error::placeholder {
  color: red;
}
.form-wrapper .checkbox-row-wrapper {
  width: 100%;
  padding-right: 1.5625vw;
}
@media only screen and (max-width: 700px) {
  .form-wrapper .checkbox-row-wrapper {
    padding-right: 6.25vw;
  }
}
.form-wrapper .checkbox-row-wrapper .checkbox-row {
  position: relative;
  display: block;
  width: 100%;
}
.form-wrapper .checkbox-row-wrapper .checkbox-row input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.form-wrapper .checkbox-row-wrapper .checkbox-row p {
  font-family: "open_sans_hebrew", sans-serif;
  color: #2e2a2a;
  font-size: 0.6770833333vw;
}
@media only screen and (max-width: 700px) {
  .form-wrapper .checkbox-row-wrapper .checkbox-row p {
    font-size: 3.4375vw;
  }
}
.form-wrapper .checkbox-row-wrapper .checkbox-row p a {
  color: #2e2a2a;
}
.form-wrapper .checkbox-row-wrapper .checkbox-row p a:hover {
  text-decoration: none;
}
.form-wrapper .checkbox-row-wrapper .checkbox-row .controll {
  position: absolute;
  top: 0;
  right: -1.5625vw;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 0.2604166667vw;
  border: solid 1px #75af5d;
}
@media only screen and (max-width: 700px) {
  .form-wrapper .checkbox-row-wrapper .checkbox-row .controll {
    right: -6.25vw;
    width: 14px;
    height: 14px;
    border-radius: 0.78125vw;
  }
}
.form-wrapper .checkbox-row-wrapper .checkbox-row .controll::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 7px;
  display: none;
  width: 7px;
  height: 4px;
  border: 2px solid #2e2a2a;
  border-right: 0;
  border-top: 0;
  transform: translate(-50%, -50%) rotate(-45deg);
}
@media only screen and (max-width: 700px) {
  .form-wrapper .checkbox-row-wrapper .checkbox-row .controll::before {
    top: 6px;
    left: 6px;
  }
}
.form-wrapper .checkbox-row-wrapper .checkbox-row .controll.active::before {
  display: block;
}
.form-wrapper .checkbox-row-wrapper .checkbox-row .controll.error {
  border: solid 1px red;
}
.form-wrapper .btn-row {
  width: 100%;
  padding-top: 2.2916666667vw;
}
@media only screen and (max-width: 700px) {
  .form-wrapper .btn-row {
    padding-top: 2.1875vw;
  }
}
.form-wrapper .btn-row a {
  display: flex;
  width: 16.7708333333vw;
  height: 2.34375vw;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  line-height: 2.34375vw;
  text-align: center;
  font-family: "open_sans_hebrew", sans-serif;
  border-radius: 5.2083333333vw;
  color: #fff;
  text-decoration: none;
  background: #d36e26;
  background: -moz-linear-gradient(left, #d36e26 0%, #b63e36 100%);
  background: -webkit-linear-gradient(left, #d36e26 0%, #b63e36 100%);
  background: linear-gradient(to right, #d36e26 0%, #b63e36 100%);
}
@media only screen and (max-width: 700px) {
  .form-wrapper .btn-row a {
    width: 100%;
    height: 11.71875vw;
    line-height: 11.71875vw;
    border-radius: 5.9375vw;
  }
}
.form-wrapper .btn-row a span {
  display: inline-block;
  padding-left: 0.2604166667vw;
  font-family: "open_sans_hebrew_bold", sans-serif;
  font-size: 1.5104166667vw;
}
@media only screen and (max-width: 700px) {
  .form-wrapper .btn-row a span {
    padding-left: 0.78125vw;
    font-size: 7.5vw;
  }
}

.thanks-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 0s;
}
@media only screen and (max-width: 700px) {
  .thanks-screen {
    background-size: contain;
    background-position: top;
    background-color: #e1eccf;
  }
}
.thanks-screen.active {
  z-index: 4;
  opacity: 1;
}

.lead-created-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 0s;
}
@media only screen and (max-width: 700px) {
  .lead-created-screen {
    background-size: contain;
    background-position: top;
    background-color: #e1eccf;
  }
}
.lead-created-screen.active {
  z-index: 4;
  opacity: 1;
}

.closed-activity-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 0s;
}
@media only screen and (max-width: 700px) {
  .closed-activity-screen {
    background-size: contain;
    background-position: top;
    background-color: #e1eccf;
  }
}
.closed-activity-screen.active {
  z-index: 4;
  opacity: 1;
}

.privacy {
  position: absolute;
  bottom: 0.5208333333vw;
  left: 50%;
  transform: translate(-50%, -50%);
}
.privacy a {
  display: inline-block;
  padding-right: 0.5208333333vw;
  font-size: 0.7291666667vw;
  font-family: "open_sans_hebrew", sans-serif;
  color: #000;
  text-decoration: none;
}
@media only screen and (max-width: 700px) {
  .privacy a {
    padding-right: 1.5625vw;
    font-size: 3.59375vw;
  }
}
.privacy a:first-child {
  padding-left: 0.5208333333vw;
  border-left: 1px solid #000;
}
@media only screen and (max-width: 700px) {
  .privacy a:first-child {
    padding-left: 1.5625vw;
  }
}

.preloaderWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: all 0.1s ease;
  opacity: 0;
  visibility: hidden;
}
.preloaderWrapper.show {
  opacity: 1;
  visibility: visible;
}

.preloader {
  position: absolute;
  left: 50%;
  z-index: 100;
  width: 40px;
  height: 40px;
  transform: translate(-50%, 0);
  animation-name: preloader-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.preloader circle {
  stroke: rgb(0, 0, 0);
  stroke-width: 4px;
  stroke-dasharray: 45, 45;
  stroke-dashoffset: 0;
}

@keyframes preloader-animation {
  0% {
    transform: translate3d(-50%, 0, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, 0, 0) rotate(360deg);
  }
}