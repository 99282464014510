@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.overflowWrap {
  position: relative;
  transition: all 0.25s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
@media only screen and (max-width: 700px) {
  .overflowWrap {
    position: absolute;
    top: -1.5625vw;
    padding: 9.375vw 0.78125vw 1.5625vw 0.78125vw;
    transform: none;
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.73);
    border-radius: 3.125vw;
  }
}

.socials {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
}

.btnsWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.socialBtn {
  position: relative;
  margin-left: 1.0416666667vw;
}
@media only screen and (max-width: 700px) {
  .socialBtn {
    width: 5.9375vw;
    margin-left: 3.4375vw;
  }
}
.socialBtn svg {
  width: 100%;
}

.activeIco {
  opacity: 0;
  transition: opacity 0.35s ease-in-out;
}

.normalIco {
  transition: opacity 0.35s ease-in-out;
}

.facebookBtn,
.mailBtn,
.whatsAppBtn,
.twitterBtn {
  composes: socialBtn;
}